import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import SEO from '../../components/SEO';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="メディアキット - Spindleについて" mdxType="SEO" />
    <PageTitle title="メディアキット" enTitle="Mediakit" mdxType="PageTitle" />
    <p>{`社内資料等に利用できるSpindleのロゴデータです。利用にあたってはロゴガイドラインを参照してください。`}</p>
    <h2 {...{
      "id": "ロゴデータ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%AD%E3%82%B4%E3%83%87%E3%83%BC%E3%82%BF",
        "aria-label": "ロゴデータ permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`ロゴデータ`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/assets/spindle_logo_rgb.ai"
        }}>{`Spindle ロゴデータ（RGB）`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/assets/spindle_logo_cmyk.ai"
        }}>{`Spindle ロゴデータ（CMYK）`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/assets/spindle_LogoGuidelines.pdf"
        }}>{`Spindle ロゴガイドライン`}</a></li>
    </ul>
    <h2 {...{
      "id": "ロゴ画像",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%AD%E3%82%B4%E7%94%BB%E5%83%8F",
        "aria-label": "ロゴ画像 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`ロゴ画像`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "401px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/32a3681eed096ba411a28993984cb2d3/9144d/spindle-logo-stv.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/32a3681eed096ba411a28993984cb2d3/2aaaf/spindle-logo-stv.webp 160w", "/static/32a3681eed096ba411a28993984cb2d3/85e47/spindle-logo-stv.webp 320w", "/static/32a3681eed096ba411a28993984cb2d3/55a8c/spindle-logo-stv.webp 401w"],
              "sizes": "(max-width: 401px) 100vw, 401px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/32a3681eed096ba411a28993984cb2d3/69538/spindle-logo-stv.png 160w", "/static/32a3681eed096ba411a28993984cb2d3/72799/spindle-logo-stv.png 320w", "/static/32a3681eed096ba411a28993984cb2d3/9144d/spindle-logo-stv.png 401w"],
              "sizes": "(max-width: 401px) 100vw, 401px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/32a3681eed096ba411a28993984cb2d3/9144d/spindle-logo-stv.png",
              "alt": "Spindleのロゴマークと文字を水色に組んだロゴ画像",
              "title": "Spindleのロゴマークと文字を水色に組んだロゴ画像",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0880ff7bcad2b18bf4557f7115f0ef57/4e6ec/spindle-logo-sth.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/0880ff7bcad2b18bf4557f7115f0ef57/2aaaf/spindle-logo-sth.webp 160w", "/static/0880ff7bcad2b18bf4557f7115f0ef57/85e47/spindle-logo-sth.webp 320w", "/static/0880ff7bcad2b18bf4557f7115f0ef57/75198/spindle-logo-sth.webp 640w", "/static/0880ff7bcad2b18bf4557f7115f0ef57/f53be/spindle-logo-sth.webp 827w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/0880ff7bcad2b18bf4557f7115f0ef57/69538/spindle-logo-sth.png 160w", "/static/0880ff7bcad2b18bf4557f7115f0ef57/72799/spindle-logo-sth.png 320w", "/static/0880ff7bcad2b18bf4557f7115f0ef57/6af66/spindle-logo-sth.png 640w", "/static/0880ff7bcad2b18bf4557f7115f0ef57/4e6ec/spindle-logo-sth.png 827w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/0880ff7bcad2b18bf4557f7115f0ef57/6af66/spindle-logo-sth.png",
              "alt": "Spindleのロゴマークと文字を水平に組んだロゴ画像",
              "title": "Spindleのロゴマークと文字を水平に組んだロゴ画像",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "402px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/71ac8a675a424f95b025d5a9f830d766/0ec92/spindle-logo-symbol.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "99.375%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/71ac8a675a424f95b025d5a9f830d766/2aaaf/spindle-logo-symbol.webp 160w", "/static/71ac8a675a424f95b025d5a9f830d766/85e47/spindle-logo-symbol.webp 320w", "/static/71ac8a675a424f95b025d5a9f830d766/22cf8/spindle-logo-symbol.webp 402w"],
              "sizes": "(max-width: 402px) 100vw, 402px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/71ac8a675a424f95b025d5a9f830d766/69538/spindle-logo-symbol.png 160w", "/static/71ac8a675a424f95b025d5a9f830d766/72799/spindle-logo-symbol.png 320w", "/static/71ac8a675a424f95b025d5a9f830d766/0ec92/spindle-logo-symbol.png 402w"],
              "sizes": "(max-width: 402px) 100vw, 402px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/71ac8a675a424f95b025d5a9f830d766/0ec92/spindle-logo-symbol.png",
              "alt": "Spindleのロゴマークのみロゴ画像",
              "title": "Spindleのロゴマークのみロゴ画像",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "spindle-slack-theme",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#spindle-slack-theme",
        "aria-label": "spindle slack theme permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Spindle Slack Theme`}</h2>
    <p>{`Slack内のチャンネルやダイレクトメッセージに以下のカラーコードをペーストすると、Spindleテーマを利用できます。`}</p>
    <h3 {...{
      "id": "ライトテーマ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%A9%E3%82%A4%E3%83%88%E3%83%86%E3%83%BC%E3%83%9E",
        "aria-label": "ライトテーマ permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`ライトテーマ`}</h3>
    <pre><code parentName="pre" {...{}}>{`#FFFFFF,#2C3849,#298737,#FFFFFF,#F5F6F6,#394148,#5E9B15,#D91C0B,#FFFFFF,#08121A
`}</code></pre>
    <h3 {...{
      "id": "ダークテーマ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%80%E3%83%BC%E3%82%AF%E3%83%86%E3%83%BC%E3%83%9E",
        "aria-label": "ダークテーマ permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`ダークテーマ`}</h3>
    <pre><code parentName="pre" {...{}}>{`#141E25,#2C3849,#298737,#FFFFFF,#202930,#FFFFFF,#95C84D,#FF6A59,#202930,#FFFFFF
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      